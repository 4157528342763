import $ from 'jquery';

class Modal {
  constructor($selector) {
    $(document).on('click', $selector, ({ currentTarget }) => {
      const targetSelector =
        $(currentTarget).data().target || currentTarget.attr('href');
      const $target = $(targetSelector);
      if (!$target.is('dialog') || !$target.length) {
        throw new Error(`${targetSelector} is not a dialog element`);
      }

      // Setup close toggles
      $target.on('click', "[data-dismiss='modal']", () =>
        $target.get(0).close()
      );

      // Show the modal
      $target.get(0).showModal();
    });
  }
}

export default Modal;
