import $ from 'jquery';

const modules = require.context('.', true, /\.js$/);
modules.keys().forEach(modules);

import TaggedSubmissionText from './tagged_submission_text';
import TaggableSubmissionText from './taggable_submission_text';
import PopupMenu from './popup_menu';
import Modal from './modal';
import AlertMesages from './alert_messages';

import SiteHeader from './SiteHeader';

$(() => {
  let taggedSubmissionText = new TaggedSubmissionText('.js-tagged-submission');
  taggedSubmissionText.renderAllTags();

  new PopupMenu('.js-tabular');

  new SiteHeader();
  new Modal("[data-toggle='modal']");
  new AlertMesages();

  new TaggableSubmissionText(
    '.js-taggable-submission-text',
    taggedSubmissionText
  );
});
