import $ from 'jquery';

class PopupMenu {
  constructor(selector) {
    let $scopingSelector = $(selector);
    let revealPopUpButtonSelector = '.js-reveal-popup-menu';
    let popUpMenuSelector = '.js-popup-menu';
    let hideMenuClassName = 'js-hidden';

    if ($scopingSelector.length === 0) {
      return;
    }

    function closeAllPopupMenus() {
      $(popUpMenuSelector).each((_idx, element) => {
        $(element).addClass(hideMenuClassName);
      });
    }

    $($scopingSelector).on('click', event => {
      let $clickedElement = $(event.target);

      // If the click was on the "reveal menu" button then do nothing
      if ($clickedElement.parents(revealPopUpButtonSelector).length > 0) {
        return;
      }

      // Otherwise we close all open popup menus
      closeAllPopupMenus();
    });

    $(revealPopUpButtonSelector, $scopingSelector).on('click', event => {
      let $clickedElement = $(event.target);
      let $menuButton = $clickedElement.siblings(popUpMenuSelector);

      if ($menuButton.hasClass(hideMenuClassName)) {
        // The clicked menu is already hidden

        closeAllPopupMenus();

        // Reveal the popup menu that was clicked
        $menuButton.removeClass(hideMenuClassName);
      } else {
        // The menu is already visible

        closeAllPopupMenus();
      }
    });
  }
}

export default PopupMenu;
