import $ from 'jquery';

class SiteHeader {
  constructor() {
    let userAccountMenuSelector = '.js-user-acc-menu';
    let revealPopUpButtonSelector = '.js-user-acc-menu-open';
    let popUpMenuSelector = '.js-user-acc-menu-popup-menu';
    let hideMenuClassName = 'js-hidden';

    let $userAccountMenuPopUpMenu = $(popUpMenuSelector);

    // Any click outside the menu closes the menu
    $(document).on('click', event => {
      let $clickedElement = $(event.target);

      // If the click was on the popped up menu itself
      if ($clickedElement.parents(userAccountMenuSelector).length > 0) {
        return;
      }

      // Otherwise we close all open popup menus
      $userAccountMenuPopUpMenu.addClass(hideMenuClassName);
    });

    // Open the menu
    $(revealPopUpButtonSelector).on('click', () => {
      $userAccountMenuPopUpMenu.removeClass(hideMenuClassName);
    });
  }
}

export default SiteHeader;
