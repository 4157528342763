import $ from 'jquery';

class AlertMesages {
  constructor() {
    $(document).on('click', "[data-dismiss='alert']", ({ currentTarget }) => {
      $(currentTarget)
        .parents('.alert')
        .fadeOut(() => $(this).remove());
    });
  }
}

export default AlertMesages;
