class SubmissionTag {
  constructor(props) {
    if (props.length === 0) {
      return;
    }

    this.build = function () {
      return `<div class="js-submission-tag selected-tag" data-st-id='${props.id}' data-tag-id='${props.tagId}'>
          <span class="tag-number tag-number--colour-${props.tagColour}">${props.tagNumber}</span>
          <span class="tag-name">${props.tagName}</span>
          <button class="js-submission-tag-remove tag-delete" data-st-id='${props.id}'>&times;</button>
        </div>`;
    };
  }
}

export default SubmissionTag;
