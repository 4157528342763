import $ from 'jquery';

class TaggableSubmissionText {
  constructor(selector, taggedSubmissionText) {
    if ($(selector).length === 0) {
      return;
    }
    $('.submission-tag').on('click', function () {
      selectHTML($(this).data('tag-id'));
    });

    function selectHTML(tagId) {
      const selection = window.getSelection();

      // return early if the Selection is not valid for some reason

      if (selection.type !== 'Range') {
        displayError(
          'Your browser generated a non-range selection which we cannot process'
        );

        return;
      }

      if (selection.isCollapsed) {
        displayError(
          'Your browser generated a collapsed selection which we cannot process'
        );

        return;
      }

      if (selection.rangeCount !== 1) {
        displayError(
          'Your browser generated a multi-range selection which we cannot process'
        );

        return;
      }

      try {
        // get the range which could start and end in different DOM nodes
        const range = selection.getRangeAt(0);

        // answerId is only for survey responses
        const answerId =
          selection.anchorNode.parentElement.parentElement.dataset
            .surveyAnswerId;

        const [selectionStart, selectionEnd] =
          getFirstAndLastCharactersForSelection(selection, answerId);

        const text = selection.toString();
        const startChar = Math.min(selectionStart, selectionEnd);
        const endChar = Math.max(selectionStart, selectionEnd);
        const submissionId = $('.submission__taggable-text')[0].dataset
          .submissionId;

        /* eslint-disable camelcase */
        let params = {
          submission_tag: {
            submission_id: submissionId,
            tag_id: tagId,
            start_char: startChar,
            end_char: endChar,
            text: text,
            answer_id: answerId
          }
        };
        /* eslint-enable camelcase */

        persistSubmissionTag(params, range);
      } catch (e) {
        displayError(
          `Sorry, we failed to tag your selection because '${e.message}'`
        );
        console.error(e);
      }
    }

    function getFirstAndLastCharactersForSelection(selection, answerId) {
      const selectionAnchorIsTextContainer =
        selection.anchorNode.id === 'jsSubmissionText';
      let firstTagCharacter = 0;
      let lastTagCharacter = 0;

      if (selectionAnchorIsTextContainer) {
        // This occurs when trying to add multiple tags sequentially to the same
        // piece of highlighted text. Set the start and end values to mimic the span that is
        // generated after the first successful tag.
        const currentNode =
          selection.anchorNode.childNodes[selection.anchorOffset];
        firstTagCharacter = currentNode.dataset.startCharacter;
        lastTagCharacter = currentNode.dataset.endCharacter;

        return [firstTagCharacter, lastTagCharacter];
      }

      // selection.anchorOffset and selection.focusOffset are relative to their parent and sibling nodes.
      // This means that if a new selection is made after or within an existing tag, the offset provided will
      // start at 0 from the end of the sibling or the start of the parent node rather than the beginning of the
      // main text container. Here we need to calculate the actual offset based on the start or end values of those
      // sibling and parent nodes before adding it to the offsets provided to the get actual character values.
      const additionalAnchorOffset = getOffsetForNode(selection.anchorNode);
      const additionalFocusOffset = getOffsetForNode(selection.focusNode);
      const anchorCharacter = selection.anchorOffset + additionalAnchorOffset;
      const focusCharacter = selection.focusOffset + additionalFocusOffset;

      // Anchor is where the selection was started (where the initial click was made) and focus where
      // it was finished (where the mouse was released). Because of this we can't rely on either to consistently be the
      // sequential first or last character so we need to min/max in order to return accurate figures.
      const firstSelectionCharacter = Math.min(anchorCharacter, focusCharacter);

      // The selection offsets used above have some quirks when other html tags are present which mean
      // that characters can be added to the beginning and end of the character count in a few different ways.
      // To resolve that we check the string's index inside the larger body of text starting from
      // the first tag character calculated then use that and the selection's length to figure out the end position.
      let bodyText;
      if (answerId) {
        bodyText =
          selection.anchorNode.parentElement.parentElement.dataset
            .submissionText;
      } else {
        bodyText = $('.js-taggable-submission-text')[0].dataset.submissionText;
      }

      const selectionText = selection.toString();
      firstTagCharacter = bodyText.indexOf(
        selectionText,
        firstSelectionCharacter
      );
      lastTagCharacter = firstTagCharacter + selectionText.length - 1;

      return [firstTagCharacter, lastTagCharacter];
    }

    // Get the "offset" of the given DOM node within the original stream of
    // characters that represent the text.
    //
    // The return value is an offset into the string of characters which
    // represents the document.
    function getOffsetForNode(node) {
      const idOfWrapperNode = 'jsSubmissionText';

      const previousSiblingExists = node.previousSibling !== null;
      const previousSiblingIsTagMilestone =
        previousSiblingExists &&
        node.previousSibling.classList.contains('js-tag-milestone');
      const parentNodeIsWrapperNode = node.parentNode.id === idOfWrapperNode;
      const parentNodeIsAnotherTagNode = !parentNodeIsWrapperNode;

      // If the node has a previous sibling AND that sibling is one of our
      // `js-tag-milestone` elements then we know it will have a
      // `data-end-character` attribute set which indicates the end character of
      // the previous selection in the original document.
      if (previousSiblingExists) {
        if (
          previousSiblingIsTagMilestone &&
          node.previousSibling.dataset.endCharacter
        ) {
          return toNumber(
            node.previousSibling.dataset.endCharacter,
            'Trying to find last char of previous node'
          );
        }

        // If the given node has a previousSibling and it is **not** one of our
        // tag milestones then this is an unexpected case
        console.error('previousSibling exists but is not a tag milestone');
      }

      // If the given node's parent is not the overall submission wrapper
      // element then the given node must be wrapped by another tag (i.e it's
      // nested within or overlapping into another tag) so we use that parent
      // tag's startCharacter as the offset.
      if (
        parentNodeIsAnotherTagNode &&
        node.parentNode.dataset.startCharacter
      ) {
        return toNumber(
          node.parentNode.dataset.startCharacter,
          'Trying to find start char of parent node'
        );
      }

      // Otherwise, the given node was not within a DOM structure that we
      // recognised so we couldn't calculate an offset.
      return 0;
    }

    function toNumber(value, contextDescription) {
      const num = parseInt(value, 10);

      if (!Number.isFinite(num)) {
        throw new Error(`Failed: ${contextDescription}`);
      }

      return num;
    }

    function persistSubmissionTag(params, range) {
      $.ajax({
        url: '/submission_tags',
        type: 'POST',
        data: params
      })
        .done(response => {
          if (
            response.id &&
            response.tag_id &&
            response.tag &&
            response.tag.number
          ) {
            renderTag(
              response.id,
              response.tag_id,
              response.tag.full_number,
              range
            );
          } else {
            console.log('The server response was missing some required fields');
          }
        })
        .fail(response => {
          if (response && response.responseJSON) {
            displayErrors(response.responseJSON.errors);
          } else {
            console.log('The response was not in the expected format');
          }
        });
    }

    function displayError(errorMessage) {
      displayErrors([errorMessage]);
    }

    function displayErrors(errors) {
      let errorContainer = $(`
        <div class="alert alert--warning">
          <div class="alert__content">
            <ul></ul>
          </div>
          <button data-dismiss="alert" class="alert__control">&times;</button>
        </div>
      `);

      errors.forEach(error => {
        errorContainer.find('ul').append(`<li>${error}</li>`);
      });
      $('#tag_error_explanation').append(errorContainer);
    }

    function renderTag(submissionTagId, tagId, tagNumber, range) {
      let textRange = range;

      // color is derived from the leading digit of the tag number
      const colorNumber = tagNumber.charAt(0);

      // put in a milestone before the startChar
      let startMilestone = document.createElement('hr');
      startMilestone.setAttribute('class', 'js-tag-milestone');
      startMilestone.setAttribute('data-colour', colorNumber);
      startMilestone.setAttribute('data-type', 'tag-start');
      startMilestone.setAttribute('data-id', submissionTagId);
      startMilestone.setAttribute('data-tag-id', tagId);

      // put in a milestone after the endChar
      let endMilestone = document.createElement('hr');
      endMilestone.setAttribute('class', 'js-tag-milestone');
      endMilestone.setAttribute('data-colour', colorNumber);
      endMilestone.setAttribute('data-type', 'tag-end');
      endMilestone.setAttribute('data-id', submissionTagId);
      endMilestone.setAttribute('data-tag-id', tagId);

      let endRange = document.createRange();

      endRange.setStart(textRange.endContainer, textRange.endOffset);
      endRange.setEnd(textRange.endContainer, textRange.endOffset);

      endRange.insertNode(endMilestone);
      textRange.insertNode(startMilestone);

      taggedSubmissionText.rerenderTags(startMilestone, endMilestone);
    }
  }
}

export default TaggableSubmissionText;
